<template>
  <v-dialog v-model="dialog" max-width="620px">
    <v-card>
      <v-card-title>
        <v-row align="center">
          <v-col cols="1">
            <v-icon size="20" @click="dialog = false">
              {{ icons.mdiClose }}
            </v-icon>
          </v-col>

          <v-col align="center">
            <h5>{{ formType === 'create' ? 'Create Employee Group' : 'Edit Employee Group' }}</h5>
          </v-col>

          <v-col cols="1"></v-col>
        </v-row>
      </v-card-title>

      <v-divider></v-divider>

      <v-form ref="form" @submit.prevent="submitForm">
        <v-card-text class="py-8 px-8">
          <v-text-field v-model="currentEmployeeGroup.name" label="Group name" outlined :rules="nameRules">
          </v-text-field>

          <v-row dense>
            <v-col cols="12" md="6">
              <v-select
                v-model="currentEmployeeGroup.setting.approver.list"
                :items="approverList"
                :item-text="({ name, email }) => name || email"
                label="Approved by"
                return-object
                outlined
                multiple
                :rules="approverRules"
              >
                <template v-slot:prepend-item>
                  <v-subheader>Select by role</v-subheader>
                  <v-checkbox
                    v-for="item in roleList"
                    :id="item.role"
                    :key="item.role"
                    v-model="currentEmployeeGroup.setting.approver.list"
                    :label="item.role"
                    :value="item"
                    class="ml-4 mb-2"
                    multiple
                    ripple
                    hide-details
                  >
                  </v-checkbox>
                  <v-divider class="mt-2"></v-divider>
                </template>
              </v-select>
            </v-col>

            <v-col cols="12" md="6">
              <v-select
                v-model="currentEmployeeGroup.setting.approver.type"
                :items="approvalTypeList"
                label="Approval type"
                outlined
                :rules="approvalTypeRules"
              >
                <template v-slot:selection="{ item }">
                  {{ approvalTypeText[item] }}
                </template>

                <template v-slot:item="{ item }">
                  {{ approvalTypeText[item] }}
                </template>
              </v-select>
            </v-col>
          </v-row>

          <div class="mb-n2">Disbursement Type</div>
          <v-radio-group v-model="currentEmployeeGroup.setting.disbursement_type" row>
            <v-radio label="Manual" value="manual"></v-radio>
          </v-radio-group>

          <v-divider class="pb-6"></v-divider>

          <div class="mb-n2">Redeem Method</div>
          <v-radio-group row>
            <v-checkbox v-model="currentEmployeeGroup.setting.cashier.status" label="Enable cashier" dense></v-checkbox>
          </v-radio-group>

          <v-scroll-y-reverse-transition>
            <div v-if="currentEmployeeGroup.setting.cashier.status" class="mt-2">
              <vue-phone-number-input
                id="CreateEmployeeGroup"
                v-model="currentEmployeeGroup.setting.cashier.phone_number"
                color="#F15858"
                valid-color="#F15858"
                error-color="#FF4C51"
                default-country-code="ID"
                clearable
                size="lg"
                :dark="isDark"
                :error="
                  phoneNumberInputData
                    ? !phoneNumberInputData.isValid || Boolean(errors.length)
                    : Boolean(errors.length)
                "
                @update="data => (phoneNumberInputData = data)"
              />
              <p :class="errors.length ? 'mb-5' : 'mb-7'" style="font-size: 0.8rem; color: red">
                {{ errors[0] }}
              </p>

              <v-combobox
                v-model="currentEmployeeGroup.setting.cashier.bank_account"
                label="Bank Account"
                :items="bankChannels"
                item-text="name"
                outlined
                return-object
              >
              </v-combobox>

              <v-text-field
                v-model="currentEmployeeGroup.setting.cashier.account_number"
                label="Account number"
                outlined
              >
              </v-text-field>
            </div>
          </v-scroll-y-reverse-transition>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="justify-end">
          <v-btn color="primary" type="submit" :loading="loading"> Save </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import VuePhoneNumberInput from 'vue-phone-number-input'
import 'vue-phone-number-input/dist/vue-phone-number-input.css'
import useAppConfig from '@core/@app-config/useAppConfig'
import { mdiClose, mdiCloseBox, mdiMinusBox, mdiCheckboxBlankOutline } from '@mdi/js'
import bankChannels from './bank-channels.json'

export default {
  name: 'CreateEmployeeGroup',
  components: { VuePhoneNumberInput },
  props: {
    value: { type: Boolean, required: false },
    projects: { type: Array, required: true },
    formType: { type: String, required: true },
  },
  setup() {
    const { isDark } = useAppConfig()
    const approvalTypeText = {
      all: 'Approve by all approver',
      min1: 'Approve by min. 1 approver',
      majority: 'Approve by majority approver',
    }

    return {
      isDark,
      approvalTypeText,
      icons: { mdiClose, mdiCloseBox, mdiMinusBox, mdiCheckboxBlankOutline },
    }
  },
  data() {
    return {
      selectedRoles: [],
      approvalTypeList: ['all', 'min1', 'majority'],
      bankChannels,
      phoneNumberInputData: null,
      loading: false,
      errors: [],

      // {R U L E S} //
      nameRules: [value => !!value || 'Name grup wajib diisi'],
      approverRules: [value => value.length !== 0 || 'Approver wajib dipilih'],
      approvalTypeRules: [value => !!value || 'Tipe approver wajib diisi'],

      /// /////////////
    }
  },
  computed: {
    dialog: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    user() {
      return this.$store.getters['auth/getUser']
    },
    instance() {
      return this.$store.getters['auth/getListInstance']
    },
    currentEmployeeGroup() {
      return this.$store.getters['manageSalary/getCurrentEmployeeGroup']
    },
    roleList() {
      return this.$store.getters['global/getRoleSettings']
    },
    userList() {
      return this.$store.getters['auth/getListUsers']
    },
    approverList() {
      return [{ header: 'Select by user' }, ...this.userList]
    },
    currentProject() {
      return this.$store.getters['manageSalary/getCurrentProject']
    },
    checkboxIcon() {
      return this.icons.mdiCheckboxBlankOutline
    },
  },
  watch: {
    formType(val) {
      if (val === 'create') {
        this.$store.commit('manageSalary/setCurrentEmployeeGroup', {
          name: '',
          ID_project: '',
          setting: {
            approver: {
              type: '',
              list: [],
            },
            disbursement_type: 'manual',
            cashier: {
              status: false,
              phone_number: '',
              account_number: '',
            },
          },
        })
      }
    },
  },
  created() {
    this.$store.dispatch('auth/fetchListUser', { sub_id: this.user.sub_id })
  },
  methods: {
    async submitForm() {
      const isValid = this.$refs.form.validate()
      if (!isValid) return
      const employeeGroupData = {
        name: this.currentEmployeeGroup.name,
        ID_project: this.currentProject.ID,
        setting: this.currentEmployeeGroup.setting,
      }

      // if (this.currentEmployeeGroup.setting.cashier.status) {
      //   const validPhoneNumber = await this.validatePhoneNumber()
      //   if (!validPhoneNumber) {
      //     this.loading = false

      //     return
      //   }
      // }

      if (this.formType === 'create') {
        const newEmployeeGroup = await this.$store.dispatch('manageSalary/createEmployeeGroup', {
          sub_id: this.user.sub_id,
          employee_group_data: employeeGroupData,
        })

        this.$router.push(`/manage-salary/employee-group/${newEmployeeGroup.id}`)
      } else if (this.formType === 'edit') {
        await this.$store.dispatch('manageSalary/editEmployeeGroup', {
          sub_id: this.user.sub_id,
          employee_group_data: { ...employeeGroupData, employeesID: this.currentEmployeeGroup.employeesID },
          id: this.currentEmployeeGroup.id,
        })
        this.$store.dispatch('manageSalary/getEmployeeGroups', { sub_id: this.user.sub_id })
      }

      this.loading = false
      this.dialog = false
    },

    // MIGHT BE USABLE IN THE FUTURE
    // async validatePhoneNumber() {
    //   if (!this.currentEmployeeGroup.setting.cashier.phone_number) {
    //     this.phoneNumberInputData = {
    //       isValid: false,
    //     }

    //     return false
    //   }

    //   if (!this.phoneNumberInputData?.isValid) {
    //     return false
    //   }

    //   if (this.currentEmployeeGroup.setting.cashier.phone_number) {
    //     const validatedPhoneNumber = await this.$store.dispatch('manageSalary/validateIfPhoneNumberAlreadyRegistered', {
    //       sub_id: this.user.sub_id,
    //       instance_ID: this.instance.value[0]?.instance_id || '',
    //       phoneNumber: this.phoneNumberInputData.formattedNumber.replace(/[+]/g, ''),
    //     })

    //     if (!validatedPhoneNumber?.data.success) {
    //       this.errors = ['This phone number is not registered.']

    //       return false
    //     }
    //     if (!validatedPhoneNumber?.data.data.length) {
    //       this.errors = ['This phone number is not registered.']

    //       return false
    //     }
    //   }

    //   return true
    // },
  },
}
</script>

<style lang="scss" scoped></style>
